/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */
 .react-calendar-heatmap {
   height: 100%;
   width: auto
 }

 .react-calendar-heatmap text {
    font-size: 10px;
    fill: #aaa;
  }
  
  .react-calendar-heatmap .react-calendar-heatmap-small-text {
    font-size: 5px;
  }
  
  .react-calendar-heatmap rect:hover {
    stroke: #555;
    stroke-width: 1px;
  }
  
  /*
   * Default color scale
   */
  
  .react-calendar-heatmap .color-empty {
    fill: #eeeeee;
  }
  
  .react-calendar-heatmap .color-filled {
    fill: #8cc665;
  }
  
  /*
   * Completed color scale
   */
  
  .react-calendar-heatmap .color-github-0 {
    fill: #eeeeee;
  }
  .react-calendar-heatmap .color-github-1 {
    fill: #d6e685;
  }
  .react-calendar-heatmap .color-github-2 {
    fill: #8cc665;
  }
  .react-calendar-heatmap .color-github-3 {
    fill: #44a340;
  }
  .react-calendar-heatmap .color-github-4 {
    fill: #1e6823;
  }
  
  /*
   * Gitlab color scale
   */
  
  .react-calendar-heatmap .color-gitlab-0 {
    fill: #ededed;
  }
  .react-calendar-heatmap .color-gitlab-1 {
    fill: #acd5f2;
  }
  .react-calendar-heatmap .color-gitlab-2 {
    fill: #7fa8d1;
  }
  .react-calendar-heatmap .color-gitlab-3 {
    fill: #49729b;
  }
  .react-calendar-heatmap .color-gitlab-4 {
    fill: #254e77;
  }

    /*
   * Incomplete color scale
   */
  
   .react-calendar-heatmap .color-incomplete-0 {
    fill: #ededed;
  }
  .react-calendar-heatmap .color-incomplete-1 {
    fill: #f3ed99;
  }
  .react-calendar-heatmap .color-incomplete-2 {
    fill: #eaaa00;
  }
  .react-calendar-heatmap .color-incomplete-3 {
    fill: #f68d2e;
  }
  .react-calendar-heatmap .color-incomplete-4 {
    fill: #bc204b;
  }
  
  .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus,  .react-calendar__tile--active{
    background: inherit;
    background-color: inherit;
    color: inherit;
  }

  /* .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus{
    background: inherit;
    background-color: inherit;
    color: inherit;
  } */

  .react-calendar {
    border:none;
    font-family: sans-serif;
  }

  abbr[title], acronym[title] {
    text-decoration:none;
    color:inherit;
  }

  .react-calendar button {
    padding-top: 16px;
    padding-bottom: 16px;
  }